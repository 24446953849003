import './style.css'

export default function Segmentos() {
    return (
        <div className='segmentos'>
            <div className='segmentos-area'>
                <div className='segmentos-img'>
                <img src='./HUB_prancheta.png' alt='Prancheta HUB'/>
                </div>
                <div className='segmentos-accordion'>
                    <h2>segmentos</h2>
                    <details className='accordion-item'>
                        <summary><div className='circle cinza'></div>ENGENHARIA</summary>
                        <span>Construção, incorporação e vendas</span>
                    </details>
                    <details className='accordion-item'>
                        <summary><div className='circle roxo'></div>FINANCE</summary>
                        <span>Open banking, serviços e seguros</span>
                    </details>
                    <details className='accordion-item'>
                        <summary><div className='circle vermelho'></div>INVESTIMENTO</summary>
                        <span>Mesa proprietária de investimento e programas educacionais</span>
                    </details>
                    <details className='accordion-item'>
                        <summary><div className='circle azul'></div>FRANQUIAS</summary>
                        <span>Gestão e expansão</span>
                    </details>
                    <details className='accordion-item'>
                        <summary><div className='circle laranja'></div>TERCEIRIZAÇÃO</summary>
                        <span>BPS, BPO e Consultoria especializada</span>
                    </details>
                    <details className='accordion-item'>
                        <summary><div className='circle amarelo'></div>TELECOM</summary>
                        <span>Chips, rede e aplicativo</span>
                    </details>
                    <details className='accordion-item'>
                        <summary><div className='circle azulEscuro'></div>CONSULTORIA, IMPOSTOS E AUDITORIA</summary>
                        <span>Consultoria empresarial impostos, M&A e Due Diligence</span>
                    </details>
                    <details className='accordion-item'>
                        <summary><div className='circle verde'></div>SOCIAL</summary>
                        <span>Transformação de vidas</span>
                    </details>
                </div>
            </div>
        </div>
    )
}