import './App.css';
import Contato from './Components/Contato';
import Footer from './Components/Footer';
import Header from './Components/Header';
import MainSection from './Components/MainSection';
import NossaExpertise from './Components/NossaExpertise';
import Segmentos from './Components/Segmentos';
import Sobre from './Components/Sobre';
import Verticais from './Components/Verticais';

function App() {
  return (
    <div className="App">
        <Header />
        <MainSection />
        <div className='alt-bg'>
        <NossaExpertise />
        </div>
        <Verticais />
        <div className='alt-bg'>
          <Sobre />
          <Segmentos />
          <Contato />
        </div>
        <div className='footer-bg'>
          <Footer />
        </div>
    </div>
  );
}

export default App;
