import './style.css'

export default function Sobre() {
    return (
        <div id='sobre' className='sobre'>
            <div className='sobre-area'>
                <div className='sobre-texto'>
                    <h2>sobre a rinobox</h2>
                    <p>A Rinobox faz parte do Grupo AlloyMit e pauta sua atuação na transparência, equidade e responsabilidade social para transformar negócios e vidas. Baseada em sua expertise e experiência.</p>
                    <p className='sobrePDif'>Nossa missão é criar soluções <strong>inovadoras</strong>, <strong>flexíveis</strong> e <strong>seguras</strong>, que melhoram substancialmente os resultados de nossos clientes, aliando a <strong>criatividade</strong> com os pilares de <strong>transparência</strong> e <strong>governança</strong> do grupo.</p>
                    <a href='https://alloymit.com/' target="_blank" rel='noreferrer'>saiba mais sobre o grupo alloymit</a>
                </div>
                <img src='./IMG_ALLOY_SIMBOLO.png' alt='Simobolo da AlloyMit'/>
            </div>
        </div>
    )
}