import './style.css'

export default function Footer() {

    const ano = new Date().getFullYear()

    return (
        <div className='footer'>
            <div className='footer-area'>
                <div className='footer-menu'>
                    <div className='footer-img'>
                        <a href='#topo'><img src='./logo-rinobox.png' alt='Logo da RinoBox' /></a>
                        <a href='https://www.alloymit.com/' target='_blank' rel="noreferrer"><img src='./selo-alloy.png' alt='Logo da AlloyMit'/></a>
                    </div>
                    <div className='footer-menu-list'>
                        <ul>
                            <li><a href='#solucoes'>soluções</a></li>
                            <li><a href='#verticais'>verticais</a></li>
                            <li><a href='#sobre'>sobre a rinobox</a></li>
                            <li><a href='#contato'>contatos</a></li>
                        </ul>
                    </div>
                </div>
                <div className='footer-contatos'>
                    <div className='footer-dados'>
                        <div className='endereco'>
                            <i className="fa-solid fa-location-dot"></i>
                            <p>
                                ed.alloymit<br />
                                rua bartolomeu de gusmão, 290<br />
                                vila mariana - sp - cep 04111-020
                            </p>
                        </div>
                        <div className='contatos-area'>
                            <div className='mail'>
                                <i className="fa-solid fa-phone"></i>
                                <p>+55 (11) 3257-1717</p>
                            </div>
                            <div className='mail'>
                                <i className="fa-solid fa-envelope"></i>
                                <p>sac@rinobox.com.br</p>
                            </div>
                        </div>
                    </div>
                    <div className='redesSociais'>
                        <a href='https://www.instagram.com/rinoboxoficial/' target="_blank" ><i className="fa-brands fa-instagram"></i></a>
                        {/* <a href='https://www.facebook.com/rino.box/' target="_blank" ><i className="fa-brands fa-facebook"></i></a> */}
                        <a href='https://www.linkedin.com/company/rino-box/mycompany/' target="_blank" ><i className="fa-brands fa-linkedin"></i></a>
                    </div>
                </div>
                <div className='copy'>
                        <p>© copyright {ano} – RinoBox. Todos os direitos reservados.</p>
                    </div>
            </div>
        </div>
    )
}