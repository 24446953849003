import './style.css'

export default function Verticais() {
    return (
        <div id='verticais' className='verticais'>
            <div className='verticais-area'>
                <h1>conheça nossas verticais de negócio</h1>
                <div className='verticais-box'>
                    <div className='box'>
                        <img src='./allupe-logo.png' alt='Logo Allupe' height={120}/>
                        <div className='box-texto'>
                            <h2>Allupe</h2>
                            <p>Allupe é uma plataforma de comunicação que integra outros canais com seu público de forma personalizada, inteligente e tecnológica.</p>
                            <a href='https://allupe.com.br/'  target='_blank' rel='noreferrer'>ir para o site</a>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='box-texto'>
                            <h2>boss analytics</h2>
                            <p>Ferramenta completa de apoio à decisão de negócios baseada em algoritmos de Inteligência Artificial para processamento de dados de clientes e fornecedores.</p>
                            <a href='https://bossanalytics.com.br/'  target='_blank' rel='noreferrer'>ir para o site</a>
                        </div>
                        <img src='./BOSS_LOGO.png' alt='Logo Boss Analytics' height={160}/>
                    </div>
                    <div className='box'>
                        <img src='./LOGO_GRANDEBOARD.png' alt='Logo Grande Board' height={145}/>
                        <div className='box-texto'>
                            <h2>o grande board</h2>
                            <p>Plataforma de fomento e desenvolvimento de novos talentos no Futebol, conta com tecnologia proprietária de acompanhamento e tokenização.</p>
                            <a style={{backgroundColor: '#c1c1c1', cursor: 'not-allowed'}}>Em breve</a>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='box-texto'>
                            <h2>Doação Legal</h2>
                            <p>O Doação Legal nasceu com o intuito de ajudar as pessoas e suas causas auxiliando na captação de campanhas de crowdfunding. Mais de 20.300 campanhas criadas, além de 92.208 doações realizadas.</p>
                            <a href='https://doacaolegal.com.br/'  target='_blank' rel='noreferrer'>ir para o site</a>
                        </div>
                        <img src='./doacaolegal-logo.png' alt='Logo Doação Legal' height={145}/>
                    </div>
                    <div className='box'>
                        <img src='./vaquinhaeleitoral-logo.png' alt='Logo Vaquinha Eleitoral' height={125}/>
                        <div className='box-texto'>
                            <h2>Vaquinha Eleitoral</h2>
                            <p>A melhor plataforma para financiamento coletivo eleitoral, homologado pelo TSE pela quarta eleição consecutiva. Mais de 6 mil candidatos auxiliados, tudo para uma campanha completa e de sucesso.</p>
                            <a href='https://vaquinhaeleitoral.com.br/'  target='_blank' rel='noreferrer'>ir para o site</a>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='box-texto'>
                            <h2>Apoia</h2>
                            <p>Uma plataforma que conecta eleitores a candidatos para doações focadas em campanhas eleitorais.</p>
                            <a href='https://apoia.org/' target='_blank' rel='noreferrer'>ir para o site</a>
                        </div>
                        <img src='./apoia-logo.png' alt='Logo Apoia' height={145}/>
                    </div>
                    <!-- <div className='box'>
                        <img src='./okpago-logo.png' alt='Logo OkPago' height={145}/>
                        <div className='box-texto'>
                            <h2>OkPago</h2>
                            <p>A OkPago é uma plataforma exclusivamente focada no aumento de conversões de doações e arrecadações online no Brasil e exterior.</p>
                            <a style={{backgroundColor: '#c1c1c1', cursor: 'not-allowed'}}>Em breve</a>
                        </div>
                    </div>-->
                    <div className='box'>
                        <div className='box-texto'>
                            <h2>Popag</h2>
                            <p>O PoPag é uma plataforma que permite o pagamento de contas de forma fracionada. Diferencial único de mercado para parceiros. Mais tranquilidade para quem paga. Múltiplas possibilidades de oferta para quem recebe.</p>
                            <a style={{backgroundColor: '#c1c1c1', cursor: 'not-allowed'}}>Em breve</a>
                        </div>
                        <img src='./popag-logo.png' alt='Logo Popag' height={125}/>
                    </div>
                </div>
            </div>
        </div>
    )
}