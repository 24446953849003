import './style.css'
import { useState, useEffect } from 'react'

export default function Header() {

    const [scrollDown, setScrollDown] = useState('header-area')
    const [mobileScrollDown, setMobileScrollDown] = useState('mobile-area')
    const [menuList, setMenuList] = useState('')

    const [style, setStyle] = useState("drop-down");

    const changeStyle = () => {
        if (style === "drop-down") {
            setStyle("drop-down--active");
        } else {
            setStyle("drop-down");
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY >= 170) {
                setScrollDown('header-area-fixed')
                setMobileScrollDown('mobile-area-fixed')
                setMenuList('headerP')
            } else {
                setScrollDown('header-area')
                setMobileScrollDown('mobile-area')
                setMenuList('')
            }
        });
    },[])

    return (
        <div className='header'>
            <div className={scrollDown}>
                <a href='#topo'>{scrollDown == 'header-area' ? <img src='./LOGO.png' alt='Logo da RinoBox'/> : <img src='./logo-rinobox.png' alt='Logo da RinoBox'/>}</a>
                <div className='header-menu'>
                    <ul className={menuList} >
                        <li><a href='#solucoes'>soluções</a></li>
                        <li><a href='#verticais'>verticais</a></li>
                        <li><a href='#sobre'>sobre a rinobox</a></li>
                    </ul>
                    <div className='header-menu-contato'>
                        <a href='#contato'>solicitar contato</a>
                    </div>
                </div>
            </div>
            <div className={mobileScrollDown}>
                <a href='#topo'><img src='./logo-rinobox.png' alt='Logo da RinoBox'/></a>
                    <div onClick={changeStyle} className={style}>
                            <div id="dropDown" className="drop-down__button">
                                <span className="drop-down__name"><i className="fa-solid fa-bars"></i></span>
                                <div className="drop-down__menu-box">
                                    <ul className="drop-down__menu">
                                        <li><a className="drop-down__item" href='#solucoes'>soluções</a></li>
                                        <li><a className="drop-down__item" href='#verticais'>verticais</a></li>
                                        <li><a className="drop-down__item" href='#sobre'>sobre a rinobox</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    <div className='header-menu-contato'>
                        <a href='#contato'>solicitar contato</a>
                    </div>
            </div>
        </div>
    )
}