import './style.css'

export default function NossaExpertise() {
    return (
        <div id='solucoes' className='nossaExpertise'>
            <div className='nossaExpertise-area'>
                <h1 >nossa expertise compreende</h1>
                <div className='nossaExpertise-icones'>
                    <div className='icone'>
                        <img src='./ICONE_7.png' alt='Icone'/>
                        <p>Soluções financeiras e de <br/>pagamento</p>
                    </div>
                    <div className='icone'>
                        <img src='./ICONE_1.png' alt='Icone'/>
                        <p>Processamento de crédito</p>
                    </div>
                    <div className='icone'>
                        <img src='./ICONE_2.png' alt='Icone'/>
                        <p>Gestão e auditoria <br/>financeira, contábil e <br/>fiscal</p>
                    </div>
                    <div className='icone'>
                        <img src='./ICONE_3.png' alt='Icone'/>
                        <p>Data Science</p>
                    </div>
                    <div className='icone'>
                        <img src='./ICONE_4.png' alt='Icone'/>
                        <p>Governança e gestão de <br/>projetos</p>
                    </div>
                    <div className='icone'>
                        <img src='./ICONE_5.png' alt='Icone'/>
                        <p>Criação, distribuição e <br/>gestão de criptoativos</p>
                    </div>
                    <div className='icone'>
                        <img src='./ICONE_6.png' alt='Icone'/>
                        <p>Plataformas de <br/>e-commerce</p>
                    </div>
                </div>
            </div>
        </div>
    )
}