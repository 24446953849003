import './style.css'

export default function MainSection() {
    return (
        <div className='mainSection'>
            <div className='mainSection-area'>
                <div className='mainSection-texto' id='topo'>
                    <h1>Temos como premissas a<br />agilidade, eficiência e solidez<br /> de nossas criações</h1>
                </div>
            </div>
        </div>
    )
}